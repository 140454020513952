// @file Dynamic landing page store
import { trackEvent } from '@@/bits/analytics'
import environment from '@@/bits/environment'
import { navigateTo } from '@@/bits/location'
import { useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDynamicLandingPageStore = defineStore('dynamicLandingPageStore', () => {
  const landsideNavigationStore = useLandsideNavigationStore()

  const landingPageData = ref<LandingPageData | null>(null)

  const isLandingPageDataLoaded = computed(() => landingPageData.value != null)

  const hasAboutSection = computed(
    () => landingPageData.value?.aboutHeader != null && landingPageData.value?.aboutSubheader != null,
  )

  const hasImageSection = computed(
    () => landingPageData.value?.carouselImages != null && landingPageData.value?.carouselImages.length > 0,
  )

  const hasFeatureSection = computed(
    () => landingPageData.value?.featuresHeader != null && landingPageData.value?.featuresSubheader != null,
  )

  const hasMediaSection = computed(
    () => landingPageData.value?.mediaHeader != null && landingPageData.value?.mediaSubheader != null,
  )

  const hasProofSection = computed(
    () => landingPageData.value?.proofHeader != null && landingPageData.value?.proofSubheader != null,
  )

  const hasClosingSection = computed(
    () => landingPageData.value?.closingHeader != null && landingPageData.value?.closingSubheader != null,
  )

  function handleTopCTAClick(): void {
    trackEvent(
      'Dynamic Landing Page',
      'Click top cta',
      landingPageData.value?.slug ?? 'unknown',
      landsideNavigationStore.isUserLoggedIn ? 'registered' : 'unregistered',
    )
    handleCTAClick()
  }

  function handleBottomCTAClick(): void {
    trackEvent(
      'Dynamic Landing Page',
      'Click bottom cta',
      landingPageData.value?.slug ?? 'unknown',
      landsideNavigationStore.isUserLoggedIn ? 'registered' : 'unregistered',
    )
    handleCTAClick()
  }

  const BASE_URL = (() => {
    if (environment === 'development') {
      return 'https://padlet.dev'
    } else if (environment === 'staging') {
      return 'https://padlet.io'
    } else if (environment === 'e2e_test' || environment === 'e2e_ci') {
      return 'https://padlet.test'
    }
    return 'https://padlet.com'
  })()

  function handleCTAClick(): void {
    if (landsideNavigationStore.isUserLoggedIn) {
      if (landingPageData.value?.registeredCtaType === 'create_with_template') {
        navigateTo(
          `${BASE_URL}/quick-create-from-template?gallery_template_id=${landingPageData.value?.registeredCtaValue}`,
        )
      }
    } else {
      navigateTo(landingPageData.value?.unregisteredCtaValue ?? BASE_URL)
    }
  }

  function initializeState(data: { landingPageData: LandingPageData }): void {
    landingPageData.value = data.landingPageData ?? landingPageData.value
  }

  return {
    landingPageData,
    isLandingPageDataLoaded,
    hasAboutSection,
    hasImageSection,
    hasFeatureSection,
    hasMediaSection,
    hasProofSection,
    hasClosingSection,

    handleTopCTAClick,
    handleBottomCTAClick,
    initializeState,
  }
})

export interface LandingPageData {
  airtableId: string
  id: string
  slug: string
  pageTitle: string
  metaDescription: string
  landingPageName: string

  // Hero content
  heroHeader: string
  heroSubheader: string
  htmlHeroSubheader?: string
  heroCta: string

  // About content
  aboutHeader: string
  aboutSubheader: string
  aboutCard1Header: string
  aboutCard1Body: string
  aboutCard2Header: string
  aboutCard2Body: string
  aboutCard3Header: string
  aboutCard3Body: string

  // Image Section
  carouselImages: CarouselImage[]

  // Features section
  featuresHeader: string
  featuresSubheader: string
  features: Feature[]

  // Media section
  mediaHeader: string
  mediaSubheader: string
  mediaType: string
  mediaUrl: string

  // Closing section
  closingHeader: string
  closingSubheader: string
  closingCta: string

  // CTA actions
  registeredCtaType: string
  registeredCtaValue: string
  unregisteredCtaType: string
  unregisteredCtaValue: string

  // Proof content
  proofHeader: string
  proofSubheader: string
  proof: Proof[]
}

interface CarouselImage {
  imageAttachment: ImageAttachment
}

interface Feature {
  featureText: string
  imageAttachment: ImageAttachment
}

interface Proof {
  number: string
  proofText: string
  htmlProofText?: string
  imageAttachment: ImageAttachment
}

interface ImageAttachment {
  url: string
  height: number
  width: number
}
