// @file pinia store for landside quote modal state
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLandsideQuoteModalStore = defineStore('landsideQuoteModal', () => {
  const searchParams = new URLSearchParams(window.location.search)
  const isQuoteModalOpen = ref((searchParams.get('get_quote') ?? '') === '1')

  const openQuoteModal = (): void => {
    isQuoteModalOpen.value = true
  }

  const closeQuoteModal = (): void => {
    isQuoteModalOpen.value = false
  }

  return {
    isQuoteModalOpen,
    openQuoteModal,
    closeQuoteModal,
  }
})
