// @file Landside store
import type {
  AppReview,
  EducationClientLogo,
  EducationReview,
  SchoolLogo,
  TwitterReview,
} from '@@/bits/landside_helper'
import { defaultEducationReviews, HomePageCard, homepageCards } from '@@/bits/landside_helper'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLandsideStore = defineStore('landside', () => {
  const appReview = ref(homepageCards[HomePageCard.AppReview])
  const twitterReview = ref(homepageCards[HomePageCard.TwitterReview])
  const schoolLogos = ref(homepageCards[HomePageCard.Schools])
  const educationClientLogos = ref<EducationClientLogo[]>([])
  const educationReviews = ref<EducationReview[]>(defaultEducationReviews)

  // Getters

  // Actions
  function initializeState(data: {
    appReview: AppReview
    twitterReview: TwitterReview
    schoolLogos: SchoolLogo[]
    educationClientLogos: EducationClientLogo[]
    educationReviews: EducationReview[]
  }): void {
    appReview.value = data.appReview ?? appReview.value
    twitterReview.value = data.twitterReview ?? twitterReview.value
    schoolLogos.value = data.schoolLogos ?? schoolLogos.value
    educationClientLogos.value = data.educationClientLogos ?? educationClientLogos.value
    educationReviews.value = data.educationReviews ?? educationReviews.value
  }

  return {
    // State
    appReview,
    twitterReview,
    schoolLogos,
    educationClientLogos,
    educationReviews,

    // Getters

    // Actions
    initializeState,
  }
})
