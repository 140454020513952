<script setup lang="ts">
import window from '@@/bits/global'
import { cachedLandsideApp } from '@@/bits/landside_app_helper'
import { defineAsyncComponent } from '@@/bits/vue'
import { LandsidePage, useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import { useLandsideQuoteModalStore } from '@@/pinia/landside_quote_modal'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import ScreenReaderSpeechNotifications from '@@/vuecomponents/ScreenReaderSpeechNotifications.vue'
import { storeToRefs } from 'pinia'
import { onBeforeMount, onMounted, watch } from 'vue'

const LandsideHomeV2Page =
  cachedLandsideApp[LandsidePage.Home] ?? defineAsyncComponent(() => import('@@/vuecomponents/LandsideHomeV2Page.vue'))
const LandsideSandboxPage =
  cachedLandsideApp[LandsidePage.Sandbox] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideSandboxPage.vue'))
const LandsideProductPage =
  cachedLandsideApp[LandsidePage.Product] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideProductPage.vue'))
const LandsideSubscriptionPage =
  cachedLandsideApp[LandsidePage.Subscriptions] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideSubscriptionPage.vue'))
const LandsideTemplatePage =
  cachedLandsideApp[LandsidePage.Templates] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideTemplatePage.vue'))
const LandsideDynamicLandingPage =
  cachedLandsideApp[LandsidePage.DynamicLandingPage] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideDynamicLandingPage.vue'))
const LandsideBoardPage =
  cachedLandsideApp[LandsidePage.Board] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideProductHowItWorksPage.vue'))
const LandsideEducationPage =
  cachedLandsideApp[LandsidePage.Education] ??
  defineAsyncComponent(() => import('@@/vuecomponents/LandsideProductEducationPage.vue'))
const LandsideQuoteModal = defineAsyncComponent(() => import('@@/vuecomponents/LandsideQuoteModal.vue'))

const emit = defineEmits<{
  (event: 'loading'): void
  (event: 'loaded'): void
}>()

const landsideNavigationStore = useLandsideNavigationStore()
const screenReaderNotificationStore = useScreenReaderNotificationsStore()
const { currentPage } = storeToRefs(landsideNavigationStore)
const { isQuoteModalOpen } = storeToRefs(useLandsideQuoteModalStore())

// Watch for page changes to emit loading event
watch(currentPage, () => {
  emit('loading')
})

onBeforeMount(() => {
  // We set the correct page before the component mounts
  currentPage.value = window.ww.vueStartingState.currentPage ?? currentPage.value
})

onMounted(() => {
  // We add a background image for homepage in the LandsideController.
  // Remove it once the app mounts.
  if (currentPage.value === LandsidePage.Home) {
    const htmlRootElement = document.documentElement
    htmlRootElement.style.backgroundImage = ''
  }
})
</script>

<template>
  <main id="main-content" tabindex="-1">
    <ScreenReaderSpeechNotifications :message="screenReaderNotificationStore.latestScreenReaderMessage" />
    <LandsideHomeV2Page v-if="currentPage === LandsidePage.Home" @hook:mounted="emit('loaded')" />
    <LandsideProductPage v-else-if="currentPage === LandsidePage.Product" @hook:mounted="emit('loaded')" />
    <LandsideSubscriptionPage
      v-else-if="currentPage === LandsidePage.Subscriptions || currentPage === LandsidePage.Pricing"
      @hook:mounted="emit('loaded')"
    />
    <LandsideTemplatePage v-else-if="currentPage === LandsidePage.Templates" @hook:mounted="emit('loaded')" />
    <LandsideDynamicLandingPage
      v-else-if="currentPage === LandsidePage.DynamicLandingPage"
      @hook:mounted="emit('loaded')"
    />
    <LandsideSandboxPage v-else-if="currentPage === LandsidePage.Sandbox" @hook:mounted="emit('loaded')" />
    <LandsideBoardPage v-else-if="currentPage === LandsidePage.Board" @hook:mounted="emit('loaded')" />
    <LandsideEducationPage v-else-if="currentPage === LandsidePage.Education" @hook:mounted="emit('loaded')" />
    <LandsideQuoteModal v-if="isQuoteModalOpen" @hook:mounted="emit('loaded')" />
  </main>
</template>
