/**
 * @file Helpers for caching landside app
 * */

import { LandsidePage } from '@@/pinia/landside_navigation'

const cachedLandsideApp: Record<LandsidePage, undefined | unknown> = {
  [LandsidePage.Home]: undefined,
  [LandsidePage.Product]: undefined,
  [LandsidePage.Subscriptions]: undefined,
  [LandsidePage.Templates]: undefined,
  [LandsidePage.Help]: undefined,
  [LandsidePage.Careers]: undefined,
  [LandsidePage.WhatsNew]: undefined,
  [LandsidePage.Pricing]: undefined,
  [LandsidePage.DynamicLandingPage]: undefined,
  [LandsidePage.Board]: undefined,
  [LandsidePage.Sandbox]: undefined,
  [LandsidePage.Education]: undefined,
}

export { cachedLandsideApp }
