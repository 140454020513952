<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { buildUrlFromPath, signUpWithReferrerUrl } from '@@/bits/location'
import { getDemoUrl } from '@@/bits/url'
import OzAvatar from '@@/library/v4/components/OzAvatar.vue'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import type OzIconButton from '@@/library/v4/components/OzIconButton.vue'
import { OzIconButtonColorScheme, OzIconButtonSizePreset } from '@@/library/v4/components/OzIconButton.vue'
import OzPadletLogo, { OzPadletLogoSizePreset } from '@@/library/v4/components/OzPadletLogo.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { getAnchorFromElement } from '@@/library/v4/components/OzPopoverModal.vue'
import OzTabsWithSeparator, {
  OzTabsWithSeparatorAccessibilityRole,
  OzTabsWithSeparatorSizePreset,
} from '@@/library/v4/components/OzTabsWithSeparator.vue'
import { useAppBarAccountStore } from '@@/pinia/app_bar_account'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { LandsidePage, useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import { useLandsideQuoteModalStore } from '@@/pinia/landside_quote_modal'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { PopoverAnchor, VueCssClass } from '@@/types'
import LandsideHeaderFooterBorder from '@@/vuecomponents/LandsideHeaderFooterBorder.vue'
import SkipToContentLink from '@@/vuecomponents/SkipToContentLink.vue'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, ref } from 'vue'
const UserMenu = defineAsyncComponent(() => import('@@/vuecomponents/UserMenu.vue'))

const LandsideNavigationSidePanel = defineAsyncComponent(
  () => import('@@/vuecomponents/LandsideNavigationSidePanel.vue'),
)
const LandsideNavigationPopover = defineAsyncComponent(() => import('@@/vuecomponents/LandsideNavigationPopover.vue'))

const landsideNavigationStore = useLandsideNavigationStore()
const appBarAccountStore = useAppBarAccountStore()
const { isSmallerThanTabletPortrait, isSmallerThanDesktop3XL, isSmallerThanDesktop, isBiggerThanDesktopBig } =
  storeToRefs(useWindowSizeStore())
const { openQuoteModal } = useLandsideQuoteModalStore()

const { currentPage, landsidePages, currentUser } = storeToRefs(landsideNavigationStore)
const { xUserMenu } = storeToRefs(appBarAccountStore)
const { isDarkMode } = storeToRefs(useDarkModeStore())
const { showUserMenu } = appBarAccountStore

const xSidePanel = ref(false)
const xPopoverMenu = ref(false)
const menuAnchor = ref<Partial<PopoverAnchor>>({})
const navigationMenuButtonRef = ref<InstanceType<typeof OzIconButton>>()

const demoLink = computed(() =>
  getDemoUrl('external', { pageName: currentPageTitle.value?.toLowerCase(), linkLocation: 'top_bar' }),
)

const tabs = computed(() => {
  return (
    landsidePages.value
      .filter((page) => page.key !== LandsidePage.Home)
      // remove the "What's new" tab at smaller desktops to prevent cramping
      .filter(
        (page) => page.key !== LandsidePage.WhatsNew || isSmallerThanDesktop.value || isBiggerThanDesktopBig.value,
      )
  )
})

const currentPageTitle = computed(() => {
  // We don't have a 'Product' page in the header, but we still need 'Product' title in case user visits url /site/product directly
  if (currentPage.value === LandsidePage.Product) return __('Product')
  const page = landsidePages.value.find((page) => page.key === currentPage.value)
  return page?.text
})

const xPageTitle = computed(() => {
  if (!isSmallerThanDesktop.value) return false
  return currentPage.value !== LandsidePage.Home
})

function openNavigationMenu(): void {
  if (isSmallerThanTabletPortrait.value) {
    xSidePanel.value = true
  } else if (isSmallerThanDesktop.value) {
    xPopoverMenu.value = true
    updateActionMenuAnchor()
  }
}

function updateActionMenuAnchor() {
  const menuButton = navigationMenuButtonRef.value?.$el
  if (!menuButton) return

  const anchor = getAnchorFromElement(menuButton, { top: 2, left: -12.25 })
  menuAnchor.value = {
    ...anchor,
    position: 'side',
  }
}

function handleSelectPage(page: LandsidePage) {
  if ([LandsidePage.Help, LandsidePage.Careers, LandsidePage.WhatsNew].includes(page)) {
    landsideNavigationStore.setCurrentPage(page)
  } else {
    landsideNavigationStore.setCurrentPageWithSideEffects(page)
  }
}

function handleActivateTab(_e, { key }: { key: LandsidePage }) {
  if ([LandsidePage.Pricing, LandsidePage.Subscriptions].includes(key)) {
    const excludedButtonTitle = localStorage.getItem('excludedButtonTitle')
    if (excludedButtonTitle === LandsidePage.Pricing) {
      trackEvent('Homepage', 'Clicked pricing')
    } else if (excludedButtonTitle === LandsidePage.Subscriptions) {
      trackEvent('Homepage', 'Clicked subscriptions')
    }
  }

  if ([LandsidePage.Help, LandsidePage.Careers, LandsidePage.WhatsNew].includes(key)) {
    landsideNavigationStore.setCurrentPage(key)
  } else {
    landsideNavigationStore.setCurrentPageWithSideEffects(key)
  }
}

const isBoardPage = computed((): boolean => {
  return currentPage.value === LandsidePage.Board
})

const isHomePage = computed((): boolean => {
  return currentPage.value === LandsidePage.Home
})

const isSubscriptionsPage = computed((): boolean => {
  return currentPage.value === LandsidePage.Subscriptions
})

const isSandboxPage = computed((): boolean => {
  return currentPage.value === LandsidePage.Sandbox
})

const isEducationPage = computed((): boolean => {
  return currentPage.value === LandsidePage.Education
})

const isDarkModeHeader = computed<boolean | 'auto'>(() => {
  if (landsideNavigationStore.currentPage === LandsidePage.Home) return false
  if (
    landsideNavigationStore.currentPage === LandsidePage.DynamicLandingPage ||
    landsideNavigationStore.currentPage === LandsidePage.Sandbox ||
    landsideNavigationStore.currentPage === LandsidePage.Subscriptions ||
    landsideNavigationStore.currentPage === LandsidePage.Templates ||
    landsideNavigationStore.currentPage === LandsidePage.Education ||
    landsideNavigationStore.currentPage === LandsidePage.Board
  ) {
    return 'auto'
  }
  return true
})

const avatarSize = computed((): number => {
  if (isSmallerThanTabletPortrait.value) return 20
  if (isSmallerThanDesktop3XL.value) return 24
  return 40
})

const avatarButtonSize = computed((): number => {
  if (isSmallerThanTabletPortrait.value) return 32
  if (isSmallerThanDesktop3XL.value) return 40
  return 56
})

const avatarButtonDistanceFromEnd = computed(() => {
  const SCROLLBAR_WIDTH = 14

  if (isSmallerThanTabletPortrait.value) return 12 + SCROLLBAR_WIDTH
  if (isSmallerThanDesktop3XL.value) return 20 + SCROLLBAR_WIDTH
  return 32 + SCROLLBAR_WIDTH
})

const avatarButtonDistanceFromTop = computed(() => {
  if (isSmallerThanTabletPortrait.value) return 8
  if (isSmallerThanDesktop3XL.value) return 16
  return 0
})

const headerBackgroundColorClass = computed(() => {
  switch (currentPage.value) {
    case LandsidePage.DynamicLandingPage:
      return 'bg-grape-0 dark:bg-dark-ui-200'
    case LandsidePage.Sandbox:
      return 'bg-canary-0 dark:bg-dark-ui-200'
    case LandsidePage.Home:
      return isDarkModeHeader.value === 'auto' || isDarkModeHeader.value === true
        ? 'bg-[#C0EEFF] dark:bg-dark-ui-200'
        : 'bg-[#C0EEFF]'
    case LandsidePage.Board:
      return 'bg-grape-0 dark:bg-grey-800'
    case LandsidePage.Subscriptions:
      return 'bg-light-ui-200 dark:bg-grey-800'
    case LandsidePage.Templates:
      return 'bg-light-ui-200 dark:bg-grey-800'
    case LandsidePage.Education:
      return 'bg-park-0 dark:bg-grey-800'
    default:
      return isDarkModeHeader.value === 'auto' || isDarkModeHeader.value === true
        ? 'bg-grey-700 dark:bg-grey-800'
        : 'bg-grey-700'
  }
})

const useCustomTextColor = computed(() => {
  return !isDarkMode.value && (isEducationPage.value || isBoardPage.value || isSandboxPage.value)
})

const customTextColorClasses = computed<VueCssClass | undefined>(() => {
  switch (currentPage.value) {
    case LandsidePage.Education:
      return 'text-[rgba(3,51,0,0.54)] high-contrast:text-dark-text-100'
    case LandsidePage.Board:
      return 'text-[rgba(28,0,51,0.54)] high-contrast:text-dark-text-100'
    case LandsidePage.Sandbox:
      return 'text-[rgba(51,39,0,0.54)] high-contrast:text-dark-text-100'
    default:
      return ''
  }
})

const xLandsideHeaderBorder = computed<boolean>(() => {
  if (isBoardPage.value || isHomePage.value || isSandboxPage.value) return false
  return true
})

const onQuoteRequestClick = (): void => {
  trackEvent('Quotation form', 'Opened form')
  openQuoteModal()
}
</script>

<template>
  <header>
    <div :class="['top-0 z-landside-header w-full', headerBackgroundColorClass]">
      <SkipToContentLink />
      <div
        :class="[
          'relative',
          'flex justify-between items-center grow',
          'ps-2 pe-3 tablet-portrait:px-5 desktop:ps-8 desktop:pe-5 desktop-3xl:ps-10 desktop-3xl:pe-8',
          'tablet-portrait:py-3 desktop-3xl:py-0',
          !isHomePage && 'py-1',
          'desktop-3xl:h-22.5',
        ]"
      >
        <div :class="['z-landside-header-logo', 'flex items-center', 'min-w-0']">
          <OzContainedButton
            v-if="isSmallerThanDesktop"
            ref="navigationMenuButtonRef"
            data-testid="navigationButton"
            :aria-label="__('Open navigation menu')"
            :title="__('Open navigation menu')"
            :color-scheme="OzIconButtonColorScheme.SecondaryClear"
            :size-preset="OzIconButtonSizePreset.H40px"
            class="me-2"
            :dark-mode="isDarkModeHeader"
            @click="openNavigationMenu"
          >
            <template #icon>
              <OzIcon
                name="navigation_thick"
                font="oricon"
                :size="24"
                :class="useCustomTextColor ? customTextColorClasses : undefined"
              />
            </template>
          </OzContainedButton>

          <span
            v-if="xPageTitle && isSmallerThanTabletPortrait"
            :class="[
              'text-heading-3 font-semibold truncate',
              {
                'text-light-text-100': isDarkModeHeader === true,
                'text-dark-text-100': isDarkModeHeader === false,
                'text-dark-text-100 dark:text-light-text-100': isDarkModeHeader === 'auto',
              },
            ]"
            data-testid="mobilePageTitle"
          >
            {{ currentPageTitle }}
          </span>
          <OzPadletLogo
            v-else
            :size-preset="OzPadletLogoSizePreset.W72px"
            :x-crane-icon="true"
            :href="landsidePages[0].href"
            :href-mode="landsidePages[0].hrefMode"
            :dark-mode="isDarkModeHeader"
            @click="currentUser === null && handleSelectPage(LandsidePage.Home)"
          />
          <div v-if="!isSmallerThanDesktop" :class="['z-landside-header-tabs ms-5', 'flex justify-center']">
            <OzTabsWithSeparator
              :current-tab-key="currentPage"
              :tabs="tabs"
              :tabs-container-classes="'space-s-1'"
              :aria-label="__('Homepage sections')"
              :accessibility-role="OzTabsWithSeparatorAccessibilityRole.Navigation"
              :size-preset="OzTabsWithSeparatorSizePreset.H37px"
              :dark-mode="isDarkModeHeader"
              :non-selected-text-color-classes="useCustomTextColor ? customTextColorClasses : undefined"
              selected-text-color-classes="!text-dark-text-100 dark:!text-light-text-100"
              @activate-tab="handleActivateTab"
            />
          </div>
        </div>

        <!-- We show the user profile button if user is logged in -->
        <div
          v-if="currentUser"
          :class="[
            'z-landside-header-auth-buttons',
            'items-center flex space-s-2 desktop:space-s-4',
            isSmallerThanTabletPortrait && 'overflow-hidden p-2',
          ]"
        >
          <OzPlainButton
            data-testid="demoButton"
            :href="isSubscriptionsPage ? demoLink : undefined"
            href-target="_blank"
            :color-scheme="OzPlainButtonColorScheme.Bare"
            :size-preset="OzPlainButtonSizePreset.H32px"
            :should-truncate-text="true"
            :dark-mode="isDarkModeHeader"
            :class="[
              'min-h-10',
              isSmallerThanDesktop3XL
                ? isSmallerThanTabletPortrait
                  ? 'text-body-small'
                  : 'text-heading-4'
                : 'text-heading-3',
              isSmallerThanTabletPortrait ? 'px-2 overflow-hidden' : 'px-5',
              'rounded-2xl',
              'focus-visible:ring-[2.5px]',
              // Base
              useCustomTextColor && customTextColorClasses,
              !useCustomTextColor && [
                isDarkModeHeader === false && 'text-dark-text-200',
                isDarkModeHeader === true && 'text-light-text-200',
                isDarkModeHeader === 'auto' && 'text-dark-text-200 dark:text-light-text-200',
              ],
              isDarkModeHeader === false && [
                // Hover
                'hhover:text-dark-text-100',
                // Active
                'hactive:text-grape-500',
              ],
              isDarkModeHeader === true && [
                // Hover
                'hhover:text-light-text-100',
                // Active
                'hactive:text-canary-500',
              ],
              isDarkModeHeader === 'auto' && [
                // Hover
                'hhover:text-dark-text-100 hhover:dark:text-light-text-100',
                // Active
                'hactive:text-grape-500 hactive:dark:text-canary-500',
              ],
            ]"
            :text="isSubscriptionsPage ? __('Request demo') : __('Get a quote')"
            @click="!isSubscriptionsPage && onQuoteRequestClick()"
          />
          <OzContainedButton
            :color-scheme="OzContainedButtonColorScheme.SecondaryClear"
            :size-preset="
              isSmallerThanTabletPortrait
                ? OzContainedButtonSizePreset.H32px
                : isSmallerThanDesktop3XL
                ? OzContainedButtonSizePreset.H40px
                : OzContainedButtonSizePreset.H56px
            "
            class="shrink-0"
            :aria-label="__('Open user menu')"
            :title="__('Open user menu')"
            :dark-mode="isDarkModeHeader"
            data-testid="userProfileButton"
            @click="showUserMenu()"
          >
            <template #icon>
              <OzAvatar
                class="rounded-full"
                :width="avatarSize"
                :height="avatarSize"
                :src="currentUser.avatar"
                :alt-text-name="currentUser.name || currentUser.username"
              />
            </template>
          </OzContainedButton>
        </div>

        <!-- We show the login and sign up buttons if the user is not logged in -->
        <template v-if="!currentUser">
          <div v-if="isSmallerThanTabletPortrait" class="flex space-s-2">
            <OzContainedButton
              :color-scheme="OzContainedButtonColorScheme.Bare"
              :size-preset="OzContainedButtonSizePreset.H32px"
              href="/auth/login"
              :text="__('Log in')"
              :dark-mode="isDarkModeHeader"
              :class="[
                useCustomTextColor && customTextColorClasses,
                !useCustomTextColor && [
                  isDarkModeHeader === false && 'text-dark-text-200',
                  isDarkModeHeader === true && 'text-light-text-200',
                  isDarkModeHeader === 'auto' && 'text-dark-text-200 dark:text-light-text-200',
                ],
                isDarkModeHeader === false && [
                  // Base
                  'bg-button-light',
                  // Hover
                  'hhover:text-dark-text-100 hhover:bg-button-hover-light',
                  // Active
                  'hactive:text-light-text-100 hactive:bg-grape-500',
                ],
                isDarkModeHeader === true && [
                  // Base
                  'bg-button-dark',
                  // Hover
                  'hhover:text-light-text-100 hhover:bg-button-hover-dark',
                  // Active
                  'hactive:bg-canary-500',
                ],
                isDarkModeHeader === 'auto' && [
                  // Base
                  'bg-button-light dark:bg-button-dark',
                  // Hover
                  'hhover:text-dark-text-100 hhover:dark:text-light-text-100 hhover:bg-button-hover-light dark:hhover:bg-button-hover-dark',
                  // Active
                  'hactive:bg-grape-500 hactive:dark:bg-canary-500',
                ],
              ]"
              data-testid="logInButtonSmallerThanTabletPortraitView"
            />
            <OzContainedButton
              :href="
                isAppUsing('newSignupWelcomePage')
                  ? '/auth/signup'
                  : signUpWithReferrerUrl(buildUrlFromPath('/dashboard/make'))
              "
              data-testid="signUpButton"
              :color-scheme="OzContainedButtonColorScheme.Primary"
              :size-preset="OzContainedButtonSizePreset.H32px"
              :dark-mode="isDarkModeHeader"
            >
              {{ __('Sign up') }}
            </OzContainedButton>
          </div>
          <div v-else :class="['z-landside-header-auth-buttons', 'flex space-s-2 desktop:space-s-4']">
            <OzPlainButton
              data-testid="demoButton"
              :href="isSubscriptionsPage ? demoLink : undefined"
              href-target="_blank"
              :color-scheme="OzPlainButtonColorScheme.Bare"
              :size-preset="OzPlainButtonSizePreset.H32px"
              :dark-mode="isDarkModeHeader"
              :class="[
                'min-h-8',
                'text-body-small',
                'px-2',
                'rounded-2xl',
                'focus-visible:ring-[2.5px]',
                useCustomTextColor && customTextColorClasses,
                !useCustomTextColor && [
                  isDarkModeHeader === false && 'text-dark-text-200',
                  isDarkModeHeader === true && 'text-light-text-200',
                  isDarkModeHeader === 'auto' && 'text-dark-text-200 dark:text-light-text-200',
                ],
                isDarkModeHeader === false && [
                  // Hover
                  'hhover:text-dark-text-100',
                  // Active
                  'hactive:text-grape-500',
                ],
                isDarkModeHeader === true && [
                  // Hover
                  'hhover:text-light-text-100',
                  // Active
                  'hactive:text-canary-500',
                ],
                isDarkModeHeader === 'auto' && [
                  // Hover
                  'hhover:text-dark-text-100 hhover:dark:text-light-text-100',
                  // Active
                  'hactive:text-grape-500 hactive:dark:text-canary-500',
                ],
              ]"
              @click="!isSubscriptionsPage && onQuoteRequestClick()"
            >
              {{ isSubscriptionsPage ? __('Request demo') : __('Get a quote') }}
            </OzPlainButton>
            <OzContainedButton
              href="/auth/login"
              data-testid="logInButton"
              :color-scheme="OzContainedButtonColorScheme.Bare"
              :size-preset="OzContainedButtonSizePreset.H32px"
              :dark-mode="isDarkModeHeader"
              :class="[
                useCustomTextColor && customTextColorClasses,
                !useCustomTextColor && [
                  isDarkModeHeader === false && 'text-dark-text-200 high-contrast:text-dark-text-100',
                  isDarkModeHeader === true && 'text-light-text-200 high-contrast:text-light-text-100',
                  isDarkModeHeader === 'auto' &&
                    'text-dark-text-200 dark:text-light-text-200 high-contrast:text-dark-text-100 dark:high-contrast:text-light-text-100',
                ],
                'focus-visible:ring-2',
                isDarkModeHeader === false && [
                  // Base
                  'bg-button-light',
                  // Hover
                  'hhover:text-dark-text-100 hhover:bg-button-hover-light',
                  // Active
                  'hactive:text-light-text-100 hactive:bg-grape-500',
                ],
                isDarkModeHeader === true && [
                  // Base
                  'bg-button-dark',
                  // Hover
                  'hhover:text-light-text-100 hhover:bg-button-hover-dark',
                  // Active
                  'hactive:bg-canary-500',
                ],
                isDarkModeHeader === 'auto' && [
                  // Base
                  'bg-button-light dark:bg-button-dark',
                  // Hover
                  'hhover:text-dark-text-100 hhover:dark:text-light-text-100 hhover:bg-button-hover-light dark:hhover:bg-button-hover-dark',
                  // Active
                  'hactive:bg-grape-500 hactive:dark:bg-canary-500',
                ],
              ]"
            >
              {{ __('Log in') }}
            </OzContainedButton>
            <OzContainedButton
              :href="
                isAppUsing('newSignupWelcomePage')
                  ? '/auth/signup'
                  : signUpWithReferrerUrl(buildUrlFromPath('/dashboard/make'))
              "
              data-testid="signUpButton"
              :color-scheme="OzContainedButtonColorScheme.Primary"
              :size-preset="OzContainedButtonSizePreset.H32px"
              :dark-mode="isDarkModeHeader"
              class="high-contrast:bg-padlet-pink-700"
            >
              {{ __('Sign up') }}
            </OzContainedButton>
          </div>
        </template>

        <UserMenu
          v-if="xUserMenu"
          :user="currentUser"
          :anchor-left-offset="avatarButtonDistanceFromEnd"
          :anchor-top-offset="avatarButtonDistanceFromTop"
          :avatar-anchor-size="avatarButtonSize"
        />
      </div>

      <LandsideNavigationSidePanel
        v-if="isSmallerThanTabletPortrait && xSidePanel"
        @close="xSidePanel = false"
        @select-page="handleSelectPage"
      />
      <LandsideNavigationPopover
        v-if="isSmallerThanDesktop && xPopoverMenu"
        :popover-anchor="menuAnchor"
        @close="xPopoverMenu = false"
        @select-page="handleSelectPage"
      />
    </div>

    <LandsideHeaderFooterBorder v-if="xLandsideHeaderBorder" :dark-mode="isDarkModeHeader === true || isDarkMode" />
  </header>
</template>
