// @file Entrypoint for home page
import { captureException } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { cachedLandsideApp } from '@@/bits/landside_app_helper'
import { currentPath } from '@@/bits/location'
import { setupVueApp } from '@@/bits/vue'
import { useDynamicLandingPageStore } from '@@/pinia/dynamic_landing_page_store'
import { useExperimentsStore } from '@@/pinia/experiments'
import { useLandsideStore } from '@@/pinia/landside'
import { LandsidePage, useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import { usePlansStore } from '@@/pinia/plans'
import LandsideApp from '@@/vuecomponents/LandsideApp.vue'
import { configure as configureArvo } from '@padlet/arvo'

const { arvoConfig } = window.ww.vueStartingState
void configureArvo(arvoConfig)

// Since all components within LandsideMain are async,
// We want to fetch them in the beforeMountPromises to prevent the page from `jumping`
// when the async component loads
async function fetchLandsideAsyncComponent(): Promise<any> {
  if (currentPath() === '/') {
    const LandsideHomeV2Page = await import('@@/vuecomponents/LandsideHomeV2Page.vue')
    cachedLandsideApp[LandsidePage.Home] = LandsideHomeV2Page.default
    return
  }

  if (currentPath() === '/site/board') {
    const LandsideProductHowItWorksPage = await import('@@/vuecomponents/LandsideProductHowItWorksPage.vue')
    cachedLandsideApp[LandsidePage.Board] = LandsideProductHowItWorksPage.default
    return
  }

  if (currentPath() === '/site/sandbox') {
    const LandsideSandboxPage = await import('@@/vuecomponents/LandsideSandboxPage.vue')
    cachedLandsideApp[LandsidePage.Sandbox] = LandsideSandboxPage.default
    return
  }

  if (currentPath() === '/site/subscriptions') {
    const LandsideSubscriptionPage = await import('@@/vuecomponents/LandsideSubscriptionPage.vue')
    cachedLandsideApp[LandsidePage.Subscriptions] = LandsideSubscriptionPage.default
    return
  }

  if (currentPath() === '/site/templates') {
    const LandsideTemplatePage = await import('@@/vuecomponents/LandsideTemplatePage.vue')
    cachedLandsideApp[LandsidePage.Templates] = LandsideTemplatePage.default
    return
  }

  if (currentPath() === '/site/education') {
    const LandsideProductEducationPage = await import('@@/vuecomponents/LandsideProductEducationPage.vue')
    cachedLandsideApp[LandsidePage.Education] = LandsideProductEducationPage.default
  }
}

const beforeMountPromises: Array<Promise<any>> = [fetchLandsideAsyncComponent()]

setupVueApp({
  el: '#landside',
  rootComponent: LandsideApp,
  initializeStore(store) {
    store.dispatch('initializeState').catch(captureException)
  },
  usePinia: true,
  beforeMountPromises,
  initializePiniaStore() {
    useLandsideNavigationStore().initializeState(window.ww.vueStartingState)
    useLandsideStore().initializeState(window.ww.vueStartingState)
    usePlansStore().initializeState(window.ww.vueStartingState)
    useExperimentsStore().setUpgradeModalCopyAExperimentVariant(
      window.ww.vueStartingState.upgradeModalCopyAExperimentVariant,
    )
    useExperimentsStore().setUpgradeModalSingleStepExperimentVariant(
      window.ww.vueStartingState.upgradeModalSingleStepExperimentVariant,
    )
    useDynamicLandingPageStore().initializeState(window.ww.vueStartingState)
  },
})
  .then((vueInstance) => {
    window.app = vueInstance
  })
  .catch(captureException)
