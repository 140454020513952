<script setup lang="ts">
import { isAppUsing } from '@@/bits/flip'
import { buildUrlFromPath, signUpWithReferrerUrl } from '@@/bits/location'
import { prefetch } from '@@/bits/prefetch'
import { isRegistered } from '@@/bits/user_model'
import { defineAsyncComponent } from '@@/bits/vue'
import { useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import LandsideHeader from '@@/vuecomponents/LandsideHeader.vue'
import LandsideMain from '@@/vuecomponents/LandsideMain.vue'
import { useHighContrastMode } from '@@/vuecomposables/useHighContrastMode'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const isLandsideMainLoading = ref(true)
const LandsideFooterV2 = defineAsyncComponent(() => import('@@/vuecomponents/LandsideFooterV2.vue'))
const landsideNavigationStore = useLandsideNavigationStore()
const { currentUser } = storeToRefs(landsideNavigationStore)

const initializeFavicon = (): void => {
  // Remove existing favicon tags
  const existingFavicons = document.querySelectorAll(
    'link[rel="icon"], link[rel="apple-touch-icon"], link[rel="manifest"]',
  )
  existingFavicons.forEach((favicon) => favicon.remove())
  const fragment = document.createDocumentFragment()
  // For default case, prefer SVG over ICO for better quality
  const iconSvgLinkTag = document.createElement('link')
  iconSvgLinkTag.setAttribute('rel', 'icon')
  iconSvgLinkTag.setAttribute('type', 'image/svg+xml')
  iconSvgLinkTag.setAttribute('href', '/favicon.svg')
  fragment.appendChild(iconSvgLinkTag)
  // Fallback to ICO for browsers that don't support SVG
  const iconLinkTag = document.createElement('link')
  iconLinkTag.setAttribute('rel', 'icon')
  iconLinkTag.setAttribute('href', '/favicon.ico')
  iconLinkTag.setAttribute('sizes', '32x32')
  fragment.appendChild(iconLinkTag)
  // Apple touch icon
  const appleIconLinkTag = document.createElement('link')
  appleIconLinkTag.setAttribute('rel', 'apple-touch-icon')
  appleIconLinkTag.setAttribute('href', '/apple-touch-icon.png')
  appleIconLinkTag.setAttribute('sizes', '180x180')
  fragment.appendChild(appleIconLinkTag)
  document.head.appendChild(fragment)
}

onMounted(() => {
  initializeFavicon()
  window.addEventListener('popstate', landsideNavigationStore.switchPageToUrlParams)
  if (currentUser.value != null && !isRegistered(currentUser.value)) {
    prefetch('/auth/login')
    prefetch(
      isAppUsing('newSignupWelcomePage') ? '/auth/signup' : signUpWithReferrerUrl(buildUrlFromPath('/dashboard/make')),
    )
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('popstate', landsideNavigationStore.switchPageToUrlParams)
})

if (isAppUsing('highContrastMode')) {
  useHighContrastMode(currentUser)
}
</script>

<template>
  <div :class="['w-full', 'flex flex-col', 'bg-light-ui-100 dark:bg-dark-ui-100']">
    <LandsideHeader data-testid="landsideHeader" />
    <LandsideMain @loading="isLandsideMainLoading = true" @loaded="isLandsideMainLoading = false" />
    <LandsideFooterV2 v-show="!isLandsideMainLoading" />
  </div>
</template>

<style lang="postcss">
@import '@@/styles/5/kit';

html {
  font-size: 16px;
  color-scheme: light dark;
}

body {
  @apply scrollbar-regular dark:scrollbar-regular-dark;
  @apply scrollbar-gutter-stable;
}
</style>
